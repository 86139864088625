import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { ID } from "../../types";

export const GET_USER_OPTIONS = gql`
  query user_options($search: SearchUserOptionsAttributes) {
    userOptions(search: $search) {
      value
      label
    }
  }
`

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    firstname
    lastname
    role
    active
    email
    hasAllAccess
    environments {
      title
      subdomain
    }
  }
`;

export const GET_PAGINATED_USERS_QUERY = gql`
  query users($search: SearchUserAttributes, $page: Int, $perPage: Int, $onlyActive: Boolean) {
    paginatedUsers(search: $search, page: $page, perPage: $perPage, onlyActive: $onlyActive) {
      users {
        ...UserInfo
      }
      pagination {
        totalPage
        page
        total
        perPage
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstname
      lastname
      email
      role
      active
      locale
      hasAllAccess
      environments {
        title
        id
      }
    }
  }
`


export const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        firstname
        lastname
        email
      }
      environments
      flashMessages
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        firstname
        lastname
        email
      }
      environments
      flashMessages
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($input: DeactivateUserInput!) {
    deactivateUser(input: $input) {
      user {
        id
        firstname
        lastname
        email
      }
      flashMessages
    }
  }
`

export const DESTROY_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      flashMessages
    }
  }
`

export const SYNC_USER_ENVIRONMENTS = gql`
  mutation syncUserEnvironments($input: SyncUSerEnvironmentsInput!) {
    syncUserEnvironments(input: $input) {
      flashMessages
    }
  }
`

const useUser = (id?: ID, mutationOptions: any = {}) => {
  const [errors, setErrors] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const handleComplete = (data) => {
    setLoading(false)
    if (mutationOptions.onCompleted) {
      mutationOptions.onCompleted(data)
    }
  }

  const options = { ...mutationOptions, onCompleted: handleComplete }

  const [userCreate] = useMutation(CREATE_USER, {
    ...options
  })


  const [userUpdate] = useMutation(UPDATE_USER, {
    ...options
  })

  const [userDeactivate] = useMutation(DEACTIVATE_USER, {
    ...options
  })

  const [userDelete] = useMutation(DESTROY_USER, {
    ...options
  })

  const [syncUserEnvironments] = useMutation(SYNC_USER_ENVIRONMENTS, {
    ...options
  })

  const saveUser = (payload) => {
    const userVariables = {
      variables: {
        input: {
          ...(payload.id ? { id: payload.id} : {}),
          attributes: {
            user: {
              id: payload.id,
              email: payload.email,
              firstname: payload.firstname,
              lastname: payload.lastname,
              active: payload.active,
              role: payload.role,
              locale: payload.locale,
            },
            //@ts-ignore
            environments: payload.environments ? payload.environments.map(option => option.value) : []
          }
        }
      }, 
      onCompleted: (data: any) => {
        const { recordErrors } = data;
        if (recordErrors) setErrors(recordErrors);
      },
    };
    if(payload.id) {
      userUpdate(userVariables)
    } else {
      userCreate(userVariables)
    }
  }

  const deactivateUser = (payload) => {
    userDeactivate({
      variables: {
        input: {
          id: payload.id,
          active: payload.active
        }
      }
    })
  };

  const destroyUser = (payload) => {
    userDelete({
      variables: {
        input: {
          id: payload.id,
        }
      }
    })
  }

  const syncEnvironments = (payload) => {
    syncUserEnvironments({
      variables: {
        input: {
          id: payload.id,
        }
      }
    })
  }

  return {
    saveUser,
    deactivateUser,
    destroyUser,
    syncEnvironments,
    errors,
    loading,
  }
}


export default useUser;