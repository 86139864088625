import React, { useState } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Menu } from "antd";
import routes from "../routes";
import useI18n from "../utils/useI18n";
import styles from "./index.module.sass"
import { UserType } from "../types";

interface IProps {
  onSelect: ({ key: string }) => void;
  user: UserType;
}

const Navigation: React.FC<IProps> = props => {
  const {
    onSelect,
    user,
  } = props;
  const { t } = useI18n()

  const [routeKeys, setRoutesKeys] = useState<{selected?: string[], open?: any[]}>({});

  return (
    <div style={{ width: "40%" }}>
      <Menu
        style={{ borderBottom: "none", width: "100%" }}
        className={styles.navigation}
        onSelect={onSelect}
        selectedKeys={routeKeys.selected}
        onOpenChange={(value) => {
          setRoutesKeys({ ...routeKeys, open: value });
        }}
        mode="horizontal"
        openKeys={routeKeys.open}
      >
        {routes.map(menuItem => {
          if (menuItem && (!menuItem.allowedRoles || menuItem.allowedRoles.includes(user.role))) {
            return (
              <Menu.Item
                style={{ width: 'auto' }}
                key={menuItem.name}
              >
                {menuItem.icon && <menuItem.icon style={{ marginRight: "5px", fontSize: "20px" }} /> }
                  {t(`navigation.${menuItem.name}`)}
              </Menu.Item>
            )
          }
        })}
      </Menu>
    </div>
  )
}


const mapDispatchToProps = dispatch => ({
  onSelect: ({ key }) => {
    if (key.includes("part")) {
      const part = key.split('part=')[1]
      dispatch({
        type: "user/setPart",
        payload: part,
      })
    }
    dispatch(push(`/${key}`));
  }
});

export default connect((state, ownProps) => ({...ownProps}), mapDispatchToProps)(Navigation);