import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  Table,
  Checkbox,
  Button,
  Col,
  Row,
  Popover
} from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import useI18n from "../../../utils/useI18n";
import { GET_PAGINATED_USERS_QUERY } from '../../../utils/hooks/useUser';
import styles from "./index.module.sass";
import { AntForm } from "@9troisquarts/ant-form.ant-form";
import useSyncAllUserEnv from "../hooks/useSyncAllUserEnv";
import { UserRole } from "../../../utils/user";
import _ from "lodash";

interface UserListProps {
  user: any;
  onEdit: (id: string) => void;
}

const UserList: React.FC<UserListProps> = props => {
  const [search, setSearch] = useState<any>({ onlyActive: true, page: 1, pageSize: 30 });
  const { data, loading } = useQuery(GET_PAGINATED_USERS_QUERY, {
    variables: {
      search: {
        firstnameOrLastnameOrEmailICont: search.firstnameOrLastnameOrEmailICont,
        roleEq: search.roleEq,
        environmentsTitleICont: search.environmentsTitleICont,
      },
      page: search.page,
      perPage: search.pageSize,
      onlyActive: search.onlyActive,
    }
  });
  const { t } = useI18n();
  const  { handleSyncAllUserEnv } = useSyncAllUserEnv(false);
  const [overrideUsers, setOverrideUsers] = useState([]);
  const {
    pagination,
    users
  } = data?.paginatedUsers || {};
  
  const {
    user,
    onEdit
  } = props;
  
  const overrideUserRole = (user) => {
    const newUser = _.cloneDeep(user);
    if (user.hasAllAccess) {
      newUser.role= UserRole.MegaAdministrator;
    }

    return newUser || user;
  };

  useEffect(() => {
    if (users) {
      const updatedUsers = users.map((user) => overrideUserRole(user));
      setOverrideUsers(updatedUsers);
    }
  }, [users]);

  return (
    <div
      style={{ marginTop: 10 }}
    >
      <Row
        className={styles.searchWrapper}
      >
        <Col
          xs={10}
        >
          <AntForm
            schema={[[{
              name: "firstnameOrLastnameOrEmailICont",
              label: t('page.user.name_or_mail'),
              input: {
                type: "string",
              },
              colProps: {
                xs: 5,
              }
            }, {
              name: "roleEq",
              label: t('activerecord.attributes.users.role'),
              input: {
                type: "select",
                options: [
                  ...(user.hasAllAccess
                  ? [
                      {
                        value: "has_all_access",
                        label: t('page.user.roles.mega-administrateur'),
                      }
                    ]
                  : []),
                {
                  value: UserRole.SuperAdministrator,
                  label: t('page.user.roles.super-administrateur'),
                }, 
                {
                  value: UserRole.Administrator,
                  label: t('page.user.roles.administrateur'),
                }, 
                {
                  value: UserRole.Normal,
                  label: t('page.user.roles.normal')
                }]
              },
              colProps: {
                xs: 5,
              }
            }, {
              name: "environmentsTitleICont",
              label: t('activerecord.attributes.users.environments'),
              input: {
                type: 'string',
              },
              colProps: {
                xs: 5
              }
            }]]}
            object={search}
            onChange={(values) => setSearch({ ...search, ...values, page: 1 })}
          />
        </Col>
        <Col
          span={6}
          className={styles.actionsWrapper}
        >
          {user.role == UserRole.SuperAdministrator && (
            <div className="new-user-button">
              <Button
                type="primary"
                onClick={() => onEdit(null)}>
                  {t('page.user.new')}
              </Button>
            </div>
          )}
          <Checkbox
            checked={search.onlyActive}
            onChange={() => setSearch({ ...search, onlyActive: !search.onlyActive})}
          >
            {t('page.user.only_active')}
          </Checkbox>
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={[{
          title: t('activerecord.attributes.users.email'),
          key: 'email',
          dataIndex: 'email',
        }, {
          title: t('activerecord.attributes.users.firstname'),
          dataIndex: 'firstname',
          key: 'lastname',
        }, {
          title: t('activerecord.attributes.users.lastname'),
          key: 'lastname',
          dataIndex: 'lastname',
        }, {
          title: t('activerecord.attributes.users.active'),
          render: (text, record) => (
            <div>
              {record.active && <CheckOutlined style={{ color: "green" }} /> }
              {!record.active && <CloseOutlined style={{ color: "red" }} /> }
            </div>
          )
        }, {
          title: t('activerecord.attributes.users.role'),
          key: 'role',
          render: (text, record) => t(`page.user.roles.${record.role}`)
        }, {
					title: t('activerecord.attributes.users.environments'),
					key: 'environments',
					render: (text, record) => record.environments.map(env => env.title).join(', '),
				}, {
					render: ({ id }) => (
            <section className={styles.iconBox}>
            <Popover content={t('page.user.sync_all')}>
              <SyncOutlined  onClick={() => handleSyncAllUserEnv(id)}/>
            </Popover>
              <EditOutlined onClick={() => onEdit(id)}/>
            </section>
					),
          align: "center"
				}]}
        bordered={false}
        dataSource={ overrideUsers || users}
        onChange={(pagination) => setSearch({ ...search, page: pagination?.current, pageSize: pagination?.pageSize })}
        pagination={{
          showSizeChanger: true,
          pageSize: pagination?.perPage,
          total: pagination?.total,
          current: pagination?.page,
        }}
      />
    </div>
  )
}

export default UserList;