import React from "react";
import style from "./index.module.sass";
import accessdenied from "../../../../../public/accessdenied.png";
import useI18n from "../../utils/useI18n";

const AccessDenied: React.FC = () => {
  const { t } = useI18n()

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  //@ts-ignore
  const envTitle = params.environment; // "some_value"

  return (
    <div className={style.errorMessageWrapper}>
      <img src={accessdenied} alt="logo" className={style.imageAccessDenied} />
      <span className={style.catchPhrase}>{t("errors.access_denied.catch_phrase")}</span>
      <span className={style.subCatchPhrase}>{t(`errors.access_denied.${envTitle ? 'environment' : 'page' }`, { environment: envTitle })}</span>
    </div>
  );
};

export default AccessDenied;