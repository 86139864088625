import {useSelector} from "react-redux";
import {hasRoles} from "../authorization";
import { UserRole } from "../user";


const useAccess = () => {
  // @ts-ignore
  const currentUser = useSelector(state => state.user.current);

  return {
    canReadUsers: hasRoles(currentUser, [UserRole.SuperAdministrator, UserRole.Administrator]),
    canEditEnvironment: hasRoles(currentUser, [UserRole.SuperAdministrator, UserRole.Administrator]),
    canEditUser: hasRoles(currentUser, [UserRole.SuperAdministrator, UserRole.Administrator]),
  }
};

export default useAccess;