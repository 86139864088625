//@ts-nocheck
import Environment from "./pages/Environment/";
import User from "./pages/User";
import { UserRole } from "./utils/user";

type Route = {
  path: string;
  name: string;
  key?: string;
  allowedRoles?: string[];
  icon?: any;
  component?: React.ReactNode;
}

const routes: Route[] = [
  {
    path: '/environments',
    name: 'environments',
    component: Environment,
  },
  {
    path: '/users',
    name: 'users',
    component: User,
    allowedRoles: [UserRole.SuperAdministrator, UserRole.Administrator]
  }
]

export default routes;