import React from 'react';
import { EditOutlined, LoginOutlined, SyncOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { EnvironmentType } from '../../../types';
import styles from "./index.module.sass";
import useAccess from '../../../utils/hooks/useAccess';
import useSyncUserRole from '../hooks/useSyncUserRole';
import { Popover, Space } from 'antd';

const columns: (user: any, t: (string) => string, onEdit: (id: string) => void) => ColumnsType<EnvironmentType> = (user, t, onEdit) => {
	const { canEditEnvironment } = useAccess();
  const { handleSyncUserRole } = useSyncUserRole(false);
	const envColumns: ColumnsType<EnvironmentType> = [{
		title: t('activerecord.attributes.environments.title'),
		dataIndex: 'title',
		key: 'title',
	}, {
		title: t('activerecord.attributes.environments.subdomain'),
		key: 'subdomain',
		dataIndex: 'subdomain',
	}, {
		title: t('activerecord.attributes.environments.url'),
		key: 'url',
		dataIndex: 'url',
	}, {
		align: "right",
		render: ({ subdomain, canAccess }) => (
			<Space>
				{canAccess && (
					<div onClick={() => window.open(`/environments/${subdomain}/sign_in`)}>
						<span className={styles.loginLink}>
							<LoginOutlined style={{ marginRight: 5 }} />
							{t("words.login")}
						</span>
					</div>
				)}
			</Space>
		)
	}];

	envColumns.push({
		align: "right",
		render: ({ id }) => (
			<Space>
				{canEditEnvironment && user?.environments?.map(env => env.id).includes(parseInt(id)) && (
					<Popover content={t('page.environment.sync_all')}>
						<SyncOutlined onClick={ () => handleSyncUserRole(id) }/>
					</Popover>
				)}
				{canEditEnvironment && (<EditOutlined onClick={() => onEdit(id)} />)}
			</Space>
		)
	});

	return envColumns;
};

export default columns;