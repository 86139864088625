import { useContext, useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { AlertContext, AlertContextType } from '../../../utils/ContextProvider/AlertContext';
import { AlertType } from '../../../types';
import { AlertData } from '../../../interfaces/alert';
import { syncUserRoleMutation } from "../../../mutations/SyncUserRole";
import useI18n from "../../../utils/useI18n";


const useSyncUserRole = (isModal: Boolean) => {
  const { t } = useI18n();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertData, setAlertData] = useState<AlertData | {}>({});
	const { setAlert } = useContext(AlertContext) || {} as AlertContextType;
  const [syncUserRole] = useMutation(syncUserRoleMutation);

  useEffect(() => {
    if (Object.keys(alertData).length > 0 && setAlert && !isModal) {
      setAlert(alertData as AlertType);
      setVisibleAlert(true);
    }
  }, [alertData, setAlert, isModal]);

  const handleSyncUserRole = async (environmentId: string | number | undefined) => {
    try {
      await syncUserRole({
        variables: {
          input: {
            id: environmentId,
          },
        },
      });
    } catch (error) {
      console.error('Synch-User-Role:', error);
      setAlertData({
        type: 'error',
        message: t('page.environment.sync.error'),
        showIcon: true,
        closable:  isModal ? false : true,
      });
      setVisibleAlert(true);
    }
  };

  return { handleSyncUserRole, visibleAlert, alertData, setAlertData};
};

export default useSyncUserRole;