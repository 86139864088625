//@ts-nocheck
import React from "react";
import { ConfigProvider } from "antd";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import AntForm from "@9troisquarts/ant-form.ant-form";
import AntFormReactSelect from "@9troisquarts/ant-form.ant-form-react-select";
import fr from 'antd/lib/locale/fr_FR';
import { Provider } from "react-redux"
import { UserType } from "./types";
import store, { history } from './store';
import { ApolloProvider } from "@apollo/client";
import graphqlClient from './utils/graphqlClient';
import routes from './routes';
import Layout from "./Layout";
import Environment from "./pages/Environment";
import AccessDenied from "./components/AccessDenied";
import { AlertProvider } from '../react/utils/ContextProvider/AlertContext';

type IProps = {
  user: UserType;
}

AntForm.configure({
  formProps: {
    layout: "vertical",
  }
});

AntForm.addField('reactSelect', {
  component: AntFormReactSelect,
  loadingMessage: () => 'Loading...',
  noOptionsMessage: () => 'No options found...',
})


const { client } = graphqlClient({ url: '/graphql' });

const App: React.FC<IProps> = props => {
  return (
    <ConfigProvider locale={fr}>
      <Provider store={store(props)}>
        <ConnectedRouter history={history}>
          <ApolloProvider client={client}>
            <AlertProvider>
              <Layout>
                <Switch>
                  <Route key={"home"} exact path={"/"} component={Environment} />
                  <Route key={"access-denied"} exact path={"/access-denied"} component={AccessDenied} />
                  {routes.map(route => (
                    <Route key={route.name} exact path={route.path} component={route.component} />
                  ))}
                </Switch>
              </Layout>
            </AlertProvider>
          </ApolloProvider>
        </ConnectedRouter>
      </Provider>
    </ConfigProvider>
  )
}

export default App;