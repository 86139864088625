import React, { useState } from "react";
import {
  Table,
  Checkbox,
  Button,
  Row,
  Col
} from "antd";
import { useQuery } from "@apollo/client";
import useI18n from "../../../utils/useI18n";
import { GET_PAGINATED_ENVIRONMENTS_QUERY } from "../../../utils/hooks/useEnvironment";
import styles from "./index.module.sass";
import columns from "./columns";
import { AntForm } from "@9troisquarts/ant-form.ant-form";
import { EnvironmentListData as EnvironmentListProps } from "../../../interfaces/environment";
import { UserRole } from "../../../utils/user";


const EnvironmentList: React.FC<EnvironmentListProps> = props => {
  const [search, setSearch] = useState<any>({ onlyAccessible: true, page: 1, pageSize: 30 });
  const { data, loading } = useQuery(GET_PAGINATED_ENVIRONMENTS_QUERY, {
    variables: {
      search: {
        searchICont: search.search
      },
      page: search.page,
      perPage: search.pageSize,
      onlyAccessible: search.onlyAccessible,
    }
  })
  const {
    pagination,
    environments
  } = data?.environments || {};
  const {
    user,
    onEdit
  } = props;
  const { t } = useI18n();

  return (
    <div
      style={{ marginTop: 10 }}
    >
      <Row>
        <Col span={10}>
          <AntForm
            schema={[{
              name: "search",
              label: t('words.search'),
              input: {
                type: "string",
                placeholder: 'nom, url, nom de domaine...'
              },
              colProps: {
                xs: 5,
              }
            }]}
            object={search}
            onChange={(values) => setSearch({ ...search, ...values, page: 1 })}
          />
        </Col>
        <Col span={6}>
          <div className={styles.actionsWrapper}>
            { user.role == UserRole.SuperAdministrator && (
              <div className="new-portal-button">
                <Button
                  type="primary"
                  onClick={() => onEdit(undefined)}
                  >
                    {t('page.environment.new')}
                </Button>
              </div>
            )}
            <Checkbox
              checked={search.onlyAccessible}
              onChange={() => setSearch({ ...search, onlyAccessible: !search.onlyAccessible})}
            >
              {t('page.environment.only_mine')}
            </Checkbox>
          </div>
        </Col>
      </Row>
      <Table
        loading={loading}
        columns={columns(user, t, onEdit)}
        bordered={false}
        dataSource={environments}
        onChange={(pagination) => setSearch({ ...search, page: pagination?.current, pageSize: pagination?.pageSize })}
        pagination={{
          pageSize: pagination?.perPage,
          total: pagination?.total,
          showSizeChanger: true,
          current: pagination?.page,
        }}
      />
    </div>
  )
}

export default EnvironmentList;