import { useIntl } from "react-intl"

const useI18n = () => {
  const intl = useIntl();
  const t = (key, params = {}) => intl.formatMessage({ id: key }, params);
  const capitalized = (key) => intl.formatMessage({ id: key }).charAt(0).toUpperCase() + intl.formatMessage({ id: key }).slice(1);
  return {
    t,
    capitalized,
    translate: t,
  }
}


export default useI18n;
