import React, {
  createContext,
  useEffect,
  useState,
} from "react";
import { AlertType } from '../../types';
 
export type AlertContextType = {
  alertData: AlertType | undefined | null;
  setAlert?: (arg0: AlertType | null) => void;
};

interface IProps {
  alert: AlertContextType,
  children: React.ReactNode[] | React.ReactNode
};

export const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<IProps> = props => {
  const { children, alert } = props;
  const [alertData, setAlert] = useState<AlertType | null>();

  useEffect(() => {
    setAlert(alertData);
  }, [alert]);

  return (
    <AlertContext.Provider
      value={{
        alertData,
        setAlert,
      } as AlertContextType}
    >
      {children}
    </AlertContext.Provider>
  );
};