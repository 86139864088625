import ReactOnRails from 'react-on-rails';
import Rails from "@rails/ujs";
import 'antd/dist/antd.less';
import '../assets/stylesheets/global.sass';
import App from '../react/App';


// This is how react_on_rails can see the HelloWorld in the browser.
Rails.start();
ReactOnRails.register({
  App,
});
