import React, { useEffect } from "react";
import AntForm, { useAntForm } from "@9troisquarts/ant-form.ant-form";
import { useLazyQuery } from "@apollo/client";
import {
  Modal,
  Button,
  Tooltip
} from "antd";
import useI18n from "../../../utils/useI18n";
import { GET_ENVIRONMENT_OPTIONS } from '../../../utils/hooks/useEnvironment';
import styles from "./index.module.sass";
import {UserEditData as UserEditProps } from '../../../interfaces/user';
import useSyncAllUserEnv from "../hooks/useSyncAllUserEnv";
import _ from 'lodash';
import { UserRole } from '../../../utils/user';
import { hasPropertiesOtherThanUndefined } from "../../../utils/tools";
import useAccess from "../../../utils/hooks/useAccess";


const inputProps = {
  isMulti: true,
  menuPortalTarget: document.body,
  valueType: "string",
  menuPlacement: "bottom",
  styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
}

const UserEdit: React.FC<UserEditProps> = props => {
  const {
		editingUser,
    currentUser,
    open,
    onDestroy,
    onCancel,
    onSave,
    loading,
    onDeactivate,
    errors,
    onSyncEnvironment,
  } = props;
  const {
    t,
    capitalized
   } = useI18n();
  const { object, onChange, set, onCancel: antOnCancel } = useAntForm({});
  const { handleSyncAllUserEnv, visibleAlert, alertData: synchAllUserEnv, setAlertData } = useSyncAllUserEnv(true);
	const { canEditUser } = useAccess();
  
  useEffect(() => {
    if (open) {
    const editingUserOverride = _.cloneDeep(editingUser);
    if (editingUser.hasAllAccess) {
      editingUserOverride.role =  t('page.user.roles.mega-administrateur');
    }
    set(editingUserOverride)
    }
  }, [open, editingUser]);

  const [getEnvironments] = useLazyQuery(GET_ENVIRONMENT_OPTIONS);

  const onEnvironmentSearch = async (search) => {
    const { data: environmentOptions } = await getEnvironments({
      variables: {
				search: {
				 titleOrSubdomainCont: search
				}
      }
    })
    return environmentOptions?.environmentOptions;
  }

  const handleCancel = () => {
    set({});
    antOnCancel();
    onCancel();
  };

  const handleSync = () => {
    if (synchAllUserEnv) {
     setAlertData({});
    }
    handleSyncAllUserEnv(editingUser.id);
  }

	return (
    <Modal
      title={object?.firstname || object?.lastname ? `${object.firstname || ''} ${object.lastname || ''}` : t('page.user.new')}
      visible={open}
      width={600}
      onCancel={handleCancel}
      onOk={() => onSave(object)}
      footer={(
        <div className={styles.actionWrapper}>
          {object.id != currentUser.id && object.id && currentUser.role == UserRole.SuperAdministrator && (
            <>
              <Button
                danger
                loading={loading}
                onClick={() => {
                  if (onDestroy) {
                    onDestroy(object);
                  }
                }}
              >
                {t('page.user.destroy')}
              </Button>
              <Button
                loading={loading}
                onClick={() => {
                  if (onDeactivate) {
                    onDeactivate({ id: object.id, active: !object.active })
                  }
                }}
              >
                {t(`page.user.${object.active ? 'deactivate' : 'activate' }`)}
              </Button>
            </>
          )}
          {( object.id && object.id == currentUser.id && (
            <Tooltip
              title={t('page.user.sync_environment.warning')}
            >
              <Button
                loading={loading}
                onClick={() =>  {
                  if (onSyncEnvironment) {
                    onSyncEnvironment(object)
                  }
                }}
              >
                {t(`page.user.sync_environment.label`)}
              </Button>
            </Tooltip>
          ))}
          <div className={styles.submitWrapper}>
            <Button
              loading={loading}
              type="primary"
              onClick={() => onSave(object)}
            >
              {t('words.save')}
            </Button>
          </div>
        </div>
      )}
    >
      <AntForm
        object={object}
        errors={errors}
        className={styles.formWrapper}
        // @ts-ignore
        onChange={onChange}
        schema={[{
          name: "firstname",
          label: t('activerecord.attributes.users.firstname'),
          input: {
            type: "string"
          }
        }, {
          name: "lastname",
          label: t('activerecord.attributes.users.lastname'),
          input: {
            type: "string"
          }
        }, {
          name: "email",
          label: t('activerecord.attributes.users.email'),
          input: {
            inputProps: {
              disabled: ["administrateur", "normal"].includes(currentUser.role || ""),
            },
            type: "string"
          }
        }, {
          name: "locale",
          label: t('activerecord.attributes.users.locale'),
          input: {
            type: "select",
            options: [{
              value: "fr",
              label: capitalized('locales.fr')
            }, {
              value: "es",
              label: capitalized('locales.es')
            }, {
              value: "en",
              label: capitalized('locales.en')
            }]
          }
        }, {
          name: "role",
          label: t('activerecord.attributes.users.role'),
          input: {
            inputProps: {
              disabled: ["administrateur", "normal"].includes(currentUser.role || ""),
            },
            type: "select",
            options: [
              ...(currentUser.hasAllAccess
              ? [
                  {
                    value: "has_all_access",
                    label: t('page.user.roles.mega-administrateur'),
                  }
                ]
              : []),
            {
              value: UserRole.SuperAdministrator,
              label: t('page.user.roles.super-administrateur'),
            }, 
            {
              value: UserRole.Administrator,
              label: t('page.user.roles.administrateur'),
            }, 
            {
              value: UserRole.Normal,
              label: t('page.user.roles.normal')
            }]
          }
        }, 
        //@ts-ignore
        { ...(object.id != currentUser.id ? {
          name: "environments",
          label: "Environnements accessible",
          input: {
            //@ts-ignore
            type: 'reactSelect',
            loadOptions: onEnvironmentSearch,
            options: {
              //@ts-ignore
              optionLabel: 'label',
              optionValue: 'value',
            },
            //@ts-ignore
            inputProps: inputProps
          }
        } : {})}]}
      />
      {canEditUser && hasPropertiesOtherThanUndefined(object) &&(
            <Button
            type="primary"
            className={styles.sync_button}
            onClick={() => handleSync() }>
             {t('page.user.sync_all')}
          </Button>
      )}
    </Modal>
  )
}

export default UserEdit;