import { useContext, useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { AlertContext, AlertContextType } from '../../../utils/ContextProvider/AlertContext';
import { AlertType } from '../../../types';
import { AlertData } from '../../../interfaces/alert';
import useI18n from "../../../utils/useI18n";
import { syncAllUserEnvMutation } from "../../../mutations/SyncAllUserEnv";

const useSyncAllUserEnv = (isModal: Boolean) => {
  const { t } = useI18n();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertData, setAlertData] = useState<AlertData | {}>({});
	const { setAlert } = useContext(AlertContext) || {} as AlertContextType;
  const [syncAllUserEnv] = useMutation(syncAllUserEnvMutation);

  useEffect(() => {
    if (Object.keys(alertData).length > 0 && setAlert && !isModal) {
      setAlert(alertData as AlertType);
      setVisibleAlert(true);
    }
  }, [alertData, setAlert, isModal]);

  const handleSyncAllUserEnv = async (userId: string | number | undefined) => {
    try {
      await syncAllUserEnv({
        variables: {
          input: {
            id: userId,
          },
        },
      });
    } catch (error) {
      console.error('Synch-User-Role:', error);
      setAlertData({
        type: 'error',
        message: t('page.environment.sync.error'),
        showIcon: true,
        closable:  isModal ? false : true,
      });
      setVisibleAlert(true);
    }
  };

  return { handleSyncAllUserEnv, visibleAlert, alertData, setAlertData};
};

export default useSyncAllUserEnv;