import { gql, useMutation } from "@apollo/client";
import { ID } from "../../types";

export const GET_ENVIRONMENT_OPTIONS = gql`
  query environment_options($search: SearchEnvironmentOptionsAttributes) {
    environmentOptions(search: $search) {
      value
      label
    }
  }
`

export const ENVIRONMENT_FRAGMENT = gql`
  fragment EnvironmentInfo on Environment {
    id
    title
    subdomain
    url
    canAccess
  }
`;

export const GET_PAGINATED_ENVIRONMENTS_QUERY = gql`
  query environments($search: SearchEnvironmentAttributes, $page: Int, $perPage: Int, $onlyAccessible: Boolean) {
    environments(search: $search, page: $page, perPage: $perPage, onlyAccessible: $onlyAccessible) {
      environments {
        ...EnvironmentInfo
      }
      pagination {
        totalPage
        page
        total
        perPage
      }
    }
  }
  ${ENVIRONMENT_FRAGMENT}
`;

export const GET_ENVIRONMENT = gql`
  query environment($id: ID!) {
    environment(id: $id) {
      id
      title
      subdomain
      users {
        id
        firstname
        lastname
      }
    }
  }
`

export const SAVE_ENVIRONMENT = gql`
  mutation createEnvironment($input: CreateEnvironmentInput!) {
    createEnvironment(input: $input) {
      environment {
        ...EnvironmentInfo
      }
      users
    }
  }
  ${ENVIRONMENT_FRAGMENT}
`;

export const CREATE_ENVIRONMENT = gql`
  mutation createEnvironment($input: CreateEnvironmentInput!) {
    createEnvironment(input: $input) {
      environment {
        ...EnvironmentInfo
      }
      users
    }
  }
  ${ENVIRONMENT_FRAGMENT}
`;

export const UPDATE_ENVIRONMENT = gql`
  mutation updateEnvironment($input: UpdateEnvironmentInput!) {
    updateEnvironment(input: $input) {
      environment {
        ...EnvironmentInfo
      }
      users
    }
  }
  ${ENVIRONMENT_FRAGMENT}
`;

const useEnvironment = (id?: ID, mutationOptions = {}) => {
  const [environmentCreate] = useMutation(CREATE_ENVIRONMENT, {
    ...(mutationOptions || {})
  });

  const [environmentUpdate] = useMutation(UPDATE_ENVIRONMENT, {
    ...(mutationOptions || {})
  })

  const saveEnvironment = (payload) => {
    const environmentVariables = {
      variables: {
        input: {
          ...(payload.id ? { id: payload.id } : {}),
          attributes: {
            environment: {
              id: payload.id,
              subdomain: payload.subdomain,
              title: payload.title,
            },
            users: payload.users ? payload.users.map(option => option.value) : []
          }
        }
      }
    }
    if (payload.id) {
      environmentUpdate(environmentVariables)
    } else {
      environmentCreate(environmentVariables)
    }
  };

  return {
    saveEnvironment,
  }
}

export default useEnvironment;