import React, { 
  useState,
  useContext,
} from 'react';
import {
  Layout as AntdLayout,
  Card,
  Dropdown,
  Menu
} from 'antd';
import { CaretDownOutlined } from "@ant-design/icons";
import { connect } from 'react-redux';
import cxLogo from "../../../../public/cx_logo.png"
import styles from "./index.module.sass";
import Navigation from '../Navigation';
import TranslationProvider from "../utils/TranslationProvider";
import useI18n from '../utils/useI18n';
import UserEdit from '../pages/User/UserEdit';
import useUser from '../utils/hooks/useUser';
import Alert from '../components/Alert';
import { AlertContext, AlertContextType } from '../utils/ContextProvider/AlertContext';

interface IProps {
  user: any;
  children: any;
}

const actionUserMenu = (t, onEditProfile) => (
    <Menu>
      <Menu.Item>
        <a
          className="item"
          onClick={() => onEditProfile()}
        >
          {t("profile.edit")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          className="item"
          href="/users/sign_out"
          data-method="delete"
          role="option"
        >
          {t("session.log_out")}
        </a>
      </Menu.Item>
    </Menu>
  );

const Layout: React.FC<IProps> = props => {
  const {
    user,
    children,
  } = props;
  const { t } = useI18n();
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const alert = useContext(AlertContext);
  
  const {
    saveUser,
    syncEnvironments,
    loading,
  } = useUser(user?.id, {
    onCompleted: (data: any) => {
      const { recordErrors, updateUser, createUser } = data;
      if (recordErrors) setErrors(recordErrors);
      else if(createUser?.user || updateUser?.user) {
        setErrors({})
        setVisible(false);
      }      
    },
    refetchQueries: [
      'users'
    ]
  })

  return (
    <AntdLayout id="layout-global-admin">
      <div className={styles.header}>
        <img src={cxLogo} style={{ height: 60 }}/>
        <Navigation
          user={user}
        />
        {visible && (
          <UserEdit 
            currentUser={user}
            editingUser={{ ...user, environments: user?.environments?.map((env) => ({ label: env.title, value: env.id.toString() })) }}
            open={visible}
            onCancel={() => setVisible(false)}
            onSave={saveUser}
            errors={errors}
            loading={loading}
            onSyncEnvironment={syncEnvironments}
          />
        )}
        <div className={styles.userAction}>
          <Dropdown
            trigger={['click']}
            overlay={actionUserMenu(t, () => setVisible(true))}
            //@ts-ignore
            getPopupContainer={() => document.getElementById("layout-global-admin")}
          >
            <div className={styles.userDropdown}>
              <img
                alt="avatar"
                src={user.avatar ? user.avatar : "/default_avatar.png"}
                className={styles.userAvatar}
              />
              <span
                className={styles.userName}
              >
                {`${user.firstname} ${user.lastname}`}
              </span>
              <CaretDownOutlined style={{ marginLeft: "5px" }} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        { alert?.alertData &&
          <Alert
            {...alert.alertData}
            style={{
              position: "absolute",
              marginTop: "5px",
              height: "64px",
              zIndex: 1000,
              right: "60px",
            }}
          />
        }
      </div>
      <div className={styles.childrenWrapper}>
        <Card>
          {children}
        </Card>
      </div>
    </AntdLayout>
  )
}

const mapStateToProps = state => ({
  user: state.user.current,
})

export default connect(mapStateToProps, null)(TranslationProvider(Layout));

