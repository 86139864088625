import React, { useEffect } from "react";
import AntForm, { useAntForm } from "@9troisquarts/ant-form.ant-form";
import { useLazyQuery } from "@apollo/client";
import {  Modal } from "antd";
import useI18n from "../../../utils/useI18n";
import { GET_USER_OPTIONS } from '../../../utils/hooks/useUser';
import { AlertType } from "../../../types";
import { Button } from "antd";
import styles from "./index.module.sass";
import useSyncUserRole from "../hooks/useSyncUserRole";
import Alert from '../../../components/Alert';
import { EnvironmentEditData as EnvironmentEditProps } from '../../../interfaces/environment';
import { hasPropertiesOtherThanUndefined } from "../../../utils/tools";
import useAccess from "../../../utils/hooks/useAccess";

const inputProps = {
  isMulti: true,
  menuPortalTarget: document.body,
  valueType: "string",
  menuPlacement: "bottom",
  styles: { menuPortal: base => ({ ...base, zIndex: 9999 }) },
};

const EnvironmentEdit: React.FC<EnvironmentEditProps> = props => {
  const {
    open,
    onCancel,
    environment,
    onSubmit,
    errors
  } = props;
  const { t } = useI18n();
  const { object, onChange, set, onCancel: antOnCancel  } = useAntForm(environment)
	const { canEditEnvironment } = useAccess();

  useEffect(() => {
    if (open) {
      set(environment)
    }
  }, [open, environment]);

  const [getUsers] = useLazyQuery(GET_USER_OPTIONS);
  const { handleSyncUserRole, visibleAlert, alertData: syncUserRoleAlertData, setAlertData } = useSyncUserRole(true);
  const onUserSearch = async (search) => {
    const { data: userOptions } = await getUsers({
      variables: {
        search: {
          firstnameOrLastnameOrEmailICont: search
        }
      }
    })
    return userOptions?.userOptions
  };

  const handleCancel = () => {
    set({});
    antOnCancel();
    onCancel();
  };

  const handleSync = () => {
    if (syncUserRoleAlertData) {
     setAlertData({});
    }
    handleSyncUserRole(props.environment.id)
  }

  return (
    <Modal
      title={hasPropertiesOtherThanUndefined(object) ? t('page.environment.edit') : t('page.environment.new')}
      open={open}
      onCancel={handleCancel}
      onOk={() => onSubmit(object)}
    >
      { visibleAlert && (
        <Alert
          {...syncUserRoleAlertData as AlertType}
          style={{
            marginBottom: "15px",
          }}
        />
      )}
      <AntForm
        object={object}
        errors={errors}
        //@ts-ignore
        onChange={onChange}
        schema={[{
          name: "title",
          label: t('activerecord.attributes.environments.title'),
          input: {
            type: "string",
          }
        }, {
          name: "subdomain",
          label: t('activerecord.attributes.environments.subdomain'),
          input: {
            type: "string",
            inputProps: {
              disabled: object.id
            }
          }
        }, 
        //@ts-ignore
        ...(object.id ? [{
          name: "users",
          label: "Utilisateurs a invité",
          input: {
            //@ts-ignore
            type: 'reactSelect',
            loadOptions: onUserSearch,
            options: {
              //@ts-ignore
              optionLabel: 'label',
              optionValue: 'value',
            },
            //@ts-ignore
            inputProps: inputProps
          }}] : [])
        ]}
      />
      {canEditEnvironment && hasPropertiesOtherThanUndefined(object) &&(
        <Button
          type="primary"
          className={styles.sync_button}
          onClick={() => handleSync() }
        >
          {t('page.environment.sync_all')}
        </Button>
      )}
    </Modal>
  );
};

export default EnvironmentEdit;