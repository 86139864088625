// @ts-nocheck
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga'
import rootReducer, { rootSaga } from './models';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const createReducer = props => {
  const store =  createStore(
    rootReducer(history),
    {
      user: {
        current: props.user,
      },
      school: props.school
    },
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), sagaMiddleware,
      ),
    ),
  );
  sagaMiddleware.run(rootSaga)
  return store
}

export default createReducer;
