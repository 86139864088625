import { all } from "@redux-saga/core/effects";
import { connectRouter } from 'connected-react-router';
import { combineReducers } from "redux";

const userReducer = (state = {}, action) => {
  return state;
}

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  user: userReducer,
});

export function* rootSaga() {
  yield all([
  ])
};

export default rootReducer;
