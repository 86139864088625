import React, { useState } from "react";
import { connect } from 'react-redux';
import { useQuery } from "@apollo/client";
import EnvironmentEdit from "./EnvironmentEdit";
import EnvironmentList from "./EnvironmentList";
import EnvironmentsRecap from "./EnvironmentsRecap";
import useEnvironment, { GET_ENVIRONMENT } from "../../utils/hooks/useEnvironment";
import { User, Environment as EnvironmentType } from "../../types";

interface EnvironmentProps {
  user: any;
}

const Environment: React.FC<EnvironmentProps> = props => {
  const {
    user,
  } = props;

  const [editOpen, setEditOpen] = useState(false);
  const [editingEnvironmentID, setEditingEnvironmentID] = useState(undefined);
  const [errors, setErrors] = useState({});

  const {
    data: editingEnvironmentData,
    loading: editingEnvironmentLoading,
  } = useQuery(GET_ENVIRONMENT, {
    variables: {
      id: editingEnvironmentID,
    },
    skip: !editingEnvironmentID,
    fetchPolicy: 'network-only', 
  });

  const editingEnvironment: EnvironmentType = editingEnvironmentData?.environment

  const {
    saveEnvironment,
  } = useEnvironment(editingEnvironment?.id, {
    onCompleted: (data) => {
      const { recordErrors, createEnvironment, updateEnvironment } = data;
      if (recordErrors) setErrors(recordErrors);
      else if(createEnvironment?.environment || updateEnvironment?.environment) {
        setErrors({});
        setEditOpen(false);
        setEditingEnvironmentID(undefined);
      }
    },
    refetchQueries: [
      'environments'
    ]
  })

  const onEdit = (id) => {
    setEditOpen(true);
    setEditingEnvironmentID(id);
  }

  const onCancel = () => {
    setErrors({});
    setEditOpen(false);
    setEditingEnvironmentID(undefined);
  }

  return (
    <>
      {editOpen && (
        <EnvironmentEdit
        open={editOpen}
        onCancel={onCancel}
        onSubmit={saveEnvironment}
        user={user}
        environment={{ ...editingEnvironment, users: editingEnvironment?.users?.map((user) => ({ label: `${user.firstname} ${user.lastname}`, value: user.id})) } }
        errors={errors}
      />
      )}
      { false &&
        <EnvironmentsRecap />
      }
      <EnvironmentList
        user={user}
        onEdit={onEdit}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.current
})

export default connect(mapStateToProps, null)(Environment);