import React, { useState, useEffect, useContext } from 'react';
import { Alert as AntdAlert } from 'antd';
import { AlertType } from '../../types';
import { AlertContext, AlertContextType } from '../../utils/ContextProvider/AlertContext';

const Alert = (props: AlertType) => {
  const {
    message,   
    description,
    type,
    showIcon = true,
    closable = true,
    displayTimeout,
    isBanner = false,
    style,
  } = props;
  const [visible, setVisible] = useState(true);
	const { setAlert } = useContext(AlertContext) || {} as AlertContextType;
  const colorMap = {
    'error': "#ff4d4f",
    'success': "#52c41a",
    'info': "#1677ff",
    'warning': "#faad14",
  };
  const color = colorMap[type] || "transparent";
  const defaultStyles = {
    borderRadius: '8px',
    border: 'solid 2px',
    borderColor: color,
  };

  useEffect(() => {
    if (displayTimeout && displayTimeout > 0) {
      const hideTimeout = setTimeout(() => {
        setVisible(false);
      }, displayTimeout);

      return () => clearTimeout(hideTimeout);
    }
  }, []);

  const handleClose = () => {
    if (setAlert) {
      setAlert(null);
    }
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <AntdAlert
          style={{
            ...defaultStyles,
            ...(style || {})
          }}
          message={message}
          description={description}
          type={type}
          showIcon={showIcon}
          closable={closable}
          onClose={handleClose}
          banner={isBanner}
        />
      )}
    </>
  );
};

export default Alert;